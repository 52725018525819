import http from "@/services/http.service";

const apiUrl = "/lots";

class PlotRecordFieldsService {
  static list(plotRecordId) {
    return http.get(`${apiUrl}/lot-records/${plotRecordId}`);
  }

  static create(payload) {
    return http.post(`${apiUrl}`, payload);
  }

  static lastLotId() {
    return http.get(`${apiUrl}/registered/lastId`);
  }

  static edit(payload) {
    return http.put(`${apiUrl}/${payload.id}`, payload);
  }

  static delete(payload) {
    return http.delete(`${apiUrl}/${payload.id}`);
  }

  static getRemainingArea(fieldId, harvest) {
    return http.get(`lot-remaining-area/${fieldId}/${harvest}`);
  }
}

export default PlotRecordFieldsService;
