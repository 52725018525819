<template>
  <div>
    <div class="mr-1 icon" :style="computeIcon()"></div>
    <span>{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: "Legend",
  props: {
    label: {
      type: String,
      required: true,
    },
    smallMargin:{
      type: Boolean,
      default: false,
    },
    color:{
      type:String,
      default:"#fff4"
    }
  },
  methods:{
    computeIcon(){
      return {
        'background-color':this.color
      }
    }
  }
}
</script>

<style scoped>
  .icon{
    display: inline-block;
    height: 7px;
    width: 7px;
    border: 1px solid black;
  }
</style>