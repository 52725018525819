<template>
  <div>
    <div v-if="openDetails" class="mt-4 font-weight-bold">
      <v-icon @click="closeView()"> mdi-arrow-left </v-icon>&nbsp;
      <span style="margin-left: 0.5rem;">Voltar</span>
    </div>

    <div v-if="openDetailsPlotRecordField" class="mt-4 mb-10 font-weight-bold">
      <v-icon @click="closeViewPlotRecordField()"> mdi-arrow-left </v-icon>&nbsp;
      <span style="margin-left: 0.5rem;">Voltar</span>
    </div>

    <toolbar v-if="!openDetailsPlotRecordField"
      :toolbarName="dynamicToolbarName" :openDetails="openDetails"/>

    <form-dialog
      :dialog="formDialog"
      :formName="dynamicFormName"
    >
      <v-form ref="formProducer">
        <v-row>
          <v-col cols="12" md="8" class="col-farm">
            <v-row>
              <v-col cols="6">
                <custom-label label="Proprietário"/>
                <simple-select :items="producers"
                        v-model="payload.producer_id"
                        itemText="user.name"
                        itemValue="producer_id"
                        placeholder="Proprietário"
                        :menuProps="{ bottom: true, offsetY: true, closeOnClick: true}"
                        required
                        :disabled="true"
                        @custom-change="loadFarms()"
                />
              </v-col>

              <v-col cols="6">
                <custom-label label="Fazenda"/>
                <simple-select :items="farms"
                        v-model="payload.farm_id"
                        itemText="name"
                        itemValue="id"
                        placeholder="Fazenda"
                        :menuProps="{ bottom: true, offsetY: true, closeOnClick: true}"
                        :disabled="true"
                        required
                />
              </v-col>

              <v-col cols="6">
                <custom-label label="Safra"/>
                <simple-text-field placeholder="Safra"
                                    mask="harvest"
                                    :disabled="true"
                                    ref="inputHarvest"
                                    v-model="payload.harvest"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>

    </form-dialog>

    <row-details 
      v-if="openDetails && !openDetailsPlotRecordField" 
      :show="openDetails" 
      :showThirdRowDetails="openDetails"
    >
      <v-row align="center">
        <v-col class="d-flex justify-start">
          <toolbar  toolbarName="Resumo da Fazenda" :openDetails="openDetails"/>
        </v-col>
        <v-spacer></v-spacer>
        <v-col v-if="authoritySlug === 'super-admin' || permissions.access_cultivate"
          class="d-flex justify-end">
          <v-icon @click="edit()"> mdi-pencil</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <col-details v-if="payload.farm.name !== undefined" name="Fazenda" :column="'12'" :item="payload.farm.name"/>
        <col-details v-if="payload.producer.user !== undefined" name="Proprietário" :column="'12'" :item="payload.producer.user.name"/>
      </v-row>
      <v-row>
        <col-details v-if="payload.farm.region !== undefined" name="Região" :column="'6'" :item="payload.farm.region && payload.farm.region + '-' + payload.farm.sub_region"/>
        <col-details v-if="payload.farm.farm_size !== undefined" name="Tamanho" :column="'6'" :item="payload.farm.farm_size + ' ha'"/>
        <col-details v-if="payload.farm.number_fields !== undefined" name="Número de Talhões" :column="'12'" :item="payload.farm.number_fields"/>
      </v-row>

      <!-- Cultivars Map -->
      <template v-slot:[`other-row-details`]>
        <plot-record-map :payload="payload" :key="componentPlotRecordFields"/>
      </template>

      <template v-slot:[`third-row-details`]>
        <plot-record-fields
          v-if="payload.farm_id !== '' && payload.harvest !== ''"
          :plotRecord="payload"
          :key="componentPlotRecordFields"
          @plot-record-field-details="viewPlotRecordFieldDetails($event)"
          @close-details="closeViewPlotRecordField()"
        />
      </template>


    </row-details>

    <!-- Plot Record Field Details -->
    <row-details v-if="!openDetails && openDetailsPlotRecordField">
      <v-row align="center">
        <v-col class="d-flex justify-start">
          <span class="font-weight-bold">Detalhes</span>
        </v-col>
      </v-row>
      <v-row>
        <col-details name="Cultura" :column="'6'" :item="payloadPlotRecordField.culture.name"/>
        <col-details name="Marca" :column="'6'" :item="payloadPlotRecordField.cultivar_brand.name"/>
        <col-details name="Cultivares" :column="'6'" :item="payloadPlotRecordField.cultivar.name"/>
        <col-details name="Data do Lote" :column="'6'" :item="payloadPlotRecordField.lot_date | date"/>
        <col-details name="Área de Semeadura" :column="'12'" :item="parseFloat(payloadPlotRecordField.sowing_area) + 'ha'"/>
        <col-details name="Talhão" :column="'12'" :item="payloadPlotRecordField.field !== null ? payloadPlotRecordField.field.name : ''"/>
        <col-details name="Produtividade do Cultivares" :column="'12'" :item="parseFloat(payloadPlotRecordField.cultivar_productivity)"/>
      </v-row>
    </row-details>
  </div>
</template>

<script>
import PlotRecords from "@/domain/records/plot-records/plot-records";
import Producers from "@/domain/producers/producers";
import Farms from "@/domain/farms/farms";

import PlotRecordFields from "./PlotRecordFields.vue";
import PlotRecordMap from "./PlotRecordMap.vue";

import Toolbar from "@/components/crud/Toolbar.vue";
import FormDialog from "@/components/crud/FormDialog";
import RowDetails from "@/components/crud/Details";
import ColDetails from "@/components/crud/DetailsItem";
import CustomLabel from "@/components/texts/CustomLabel";
import SimpleTextField from "@/components/inputs/SimpleTextField";
import SimpleSelect from "@/components/inputs/SimpleSelect.vue";

import {getItem} from '@/services/local-storage.service';

export default {
  name: "PlotRecordDetails",
  components: {
    Toolbar,
    FormDialog,
    RowDetails,
    ColDetails,
    CustomLabel,
    SimpleTextField,
    SimpleSelect,
    PlotRecordFields,
    PlotRecordMap
  },

  data: () => ({
    openDetails: true,
    openDetailsPlotRecordField: false,
    producerSelected: false,
    producers: [],
    farms: [],

    plotRecordService: null,
    producerService: null,
    farmService: null,
    fieldService: null,

    payload: {},
    payloadPlotRecordField: {},
    formDialog: false,
    dynamicFormName: "Editar Registro de Lote",

    componentPlotRecordFields: 0,

    authoritySlug: "",
    permissions: "",
    loading:false
  }),

  computed: {
    dynamicToolbarName() {
      let farm = this.payload.farm;
      let harvest = this.payload.harvest
      farm = farm.name !== undefined ? farm.name : '';
      return 'Registro de Lotes / ' + farm +" / Safra de " + harvest;
    },
  },

  methods: {
    async loadPlotRecord() {
      const loader = this.$loading.show();

      await this.plotRecordService
        .get(this.payload.id)
        .then((result) => {
          this.loading=true;
          loader.hide();
          this.payload = result;
        })
        .catch(({ response }) => {
          loader.hide();

          const { error } = response.data;

          this.$router.push({ name: "plot-records" });
          this.$toasted.show(error, {
            type: "error",
          });
        });

      this.producerSelected = true;

      this.loadFarms();
    },

    loadProducers() {
      const loader = this.$loading.show();
      this.producers = [];

      this.producerService.all().then(result => {
        result.data.map(item => {
          this.producers.push(item);
        });
      });

      loader.hide();
    },

    loadFarms() {
      const producerId = this.payload.producer_id;
      const loader = this.$loading.show();
      this.farms = [];

      this.farmService.listByProducer(producerId).then((result) => {
        result.data.map((item) => {
          this.farms.push(item);
        });
      });

      loader.hide();
    },

    edit() {
      this.formDialog = true;
    },

    closeFormDialog() {
      this.formDialog = false;
    },

    closeView() {
      this.$router.push({ name: "plot-records" });
    },

    closeViewPlotRecordField() {
      this.openDetailsPlotRecordField = false;
      this.openDetails = true;
    },

    viewPlotRecordFieldDetails(item) {
      this.openDetailsPlotRecordField = true;
      this.openDetails = false;
      this.payloadPlotRecordField = Object.assign({}, item);
    },

    validate() {
      if (this.$refs.inputHarvest.value === '') {
        this.$toasted.show("O campo safra é obrigatório.", {
          type: "error",
          position: "top-right",
        });

        return false;
      }

      return true;
    },

    save() {
      if (this.validate()) {

        const loader = this.$loading.show();

        this.plotRecordService
          .edit(this.payload)
          .then(() => {

            const farmSelected = this.farms.find(
              item => item.id === this.payload.farm_id
            );

            const producerSelected = this.producers.find(
              item => item.producer_id === this.payload.producer_id
            );

            this.payload.farm_id            = farmSelected.id;
            this.payload.farm.name          = farmSelected.name;
            this.payload.farm.region        = farmSelected.region;
            this.payload.farm.sub_region    = farmSelected.sub_region;
            this.payload.farm.farm_size     = farmSelected.farm_size;
            this.payload.farm.number_fields = farmSelected.fields;
            this.payload.producer.user.name = producerSelected.user.name;

            this.componentPlotRecordFields += 1;

            loader.hide();
            this.closeFormDialog();
            this.$toasted.show("Registro de lotes atualizado com sucesso.", {
              type: "success",
            });
          })
          .catch(({response}) => {
            loader.hide();
            const {error} = response.data;
            this.$toasted.show(error, {
              type: "error",
            });
          });
      }
    },
  },

  mounted() {
    this.payload.id = this.$route.params.plotRecordId;
    this.loadPlotRecord();
    this.loadProducers();
  },

  beforeMount() {
    this.payload           = PlotRecords.newData();
    this.plotRecordService = PlotRecords;
    this.producerService   = Producers;
    this.farmService       = Farms;

    this.authoritySlug = getItem('authoritySlug');
    this.permissions   = JSON.parse(getItem('permissions'));
  },
};
</script>
