<template>
  <div>
    <v-row align="center" class="m-0 p-0">
      <v-col lg="5" cols="8" class="d-flex justify-start">
        <toolbar
          toolbarName="Mapa | Cultivares amarrados aos lotes"
          :openDetails="true"
        />
      </v-col>
      <v-col cols="7">
        <v-row class="d-flex flex-row justify-end pt-0 pa-md-3">
          <v-col class="d-flex justify-end align-center pt-0">
            <span class="d-flex align-center justify-content-center mr-3">Mapa: </span>
            <simple-select
              class="d-flex align-center justify-content-center"
              style="width: 60%;"
              :items="filter"
              v-model="selectedFilter"
              placeholder="Mapa"
              itemText="text"
              itemValue="text"
              height="auto"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true }"
              @input="changeMap"
            />
          </v-col>
          <v-col class="d-flex justify-end pt-0">
            <span class="d-flex align-center justify-content-center mr-3">Cultura:</span>         
            <simple-select
              class="d-flex align-center justify-content-center"
              style="width: 60%;"
              :items="cultures"
              v-model="selectedCulture"
              placeholder="Mapa"
              itemText="name"
              itemValue="id"
              height="auto"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true }"
              @input="changeMap"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pa-0 pa-md-2 map-container">
      <v-container class="d-flex justify-space-between align-center">
        <span class="font-weight-bold">
          {{selectedFilter != "0" ? selectedFilter : ""}}
        </span>        
        <v-btn fab dark @click="()=> printDialog = true">
          Imprimir
        </v-btn>
      </v-container>
      <v-row>
        <v-col cols="12" md="6">
          <div ref="map" class="d-flex justify-center align-center" style="width: 100%; height: 50vh"></div>
        </v-col>
        <v-col v-if="selectedFilter === 'Produtividade'">
          <custom-label
            label="Nome do Talhão:"
            class="font-weight-bold"></custom-label>
          <p>{{ actualData.field_name }}</p>
          <custom-label
            label="Cultura:"
            class="font-weight-bold"></custom-label>
          <p>
            {{ actualCulture }}
          </p>
          <custom-label
            label="Cultivares Utilizados:"
            class="font-weight-bold"></custom-label>
          <p>
            {{ actualData.cultivars.length }}
            ( <span v-for="(cultivar, index) in actualData.cultivars" :key="index">
               {{ cultivar.name }}{{index != actualData.cultivars.length - 1 ? ',' :''}}
            </span>  )
          </p>
          <custom-label
            label="Produtividade do talhão:"
            class="font-weight-bold"></custom-label>
          <p>{{ actualData.productivity }}</p>
          <custom-label
            label="Área total plantada da fazenda:"
            class="font-weight-bold"></custom-label>
          <p>
            <span>{{Math.round(payload.farm.farm_size * (totalSowedArea / 100))}}ha</span>({{ totalSowedArea.toFixed(2) }}%)
          </p>
        </v-col>
        <v-col v-if="selectedFilter === 'Distribuição'">
          <custom-label
            label="Área total plantada da fazenda:"
            class="font-weight-bold"></custom-label>
          <p>
            <span>{{Math.round(payload.farm.farm_size * (totalSowedArea / 100))}}ha</span>
            ({{ totalSowedArea.toFixed(2) }}%)
          </p>
          <custom-label
            :label="selectedCulture ==='all' ?'Culturas:':'Cultivares Utilizados:'"
            class="font-weight-bold"></custom-label>
          <div v-if="selectedCulture !=='all'">
            <div v-for="(cultivar, index) in usedCultivars"
                :key="index">
              <custom-legend
                v-if="index<cultivars_colors.length"                
                :label="`${cultivar.name} - ${cultivar.area.toFixed(1)}ha (${cultivar.percentage.toFixed(1)}%)`"
                :color="cultivars_colors[index]"
              ></custom-legend>
            </div>
          </div>
          <div v-else>
            <custom-legend
              v-for="(culture, index) in allCultures"
              :key="index"
              :label=culture.name
              :color="cultivars_colors[index]"
            ></custom-legend>
          </div>

          <custom-label
            label="Cultivar Predominante:"
            class="font-weight-bold"
          ></custom-label>
          <p>
            {{ predominantCultivar.name }} - {{predominantCultivar.area.toFixed(1)}}ha({{ predominantCultivar.percentage.toFixed(1) }}%)
          </p>
        </v-col>
      </v-row>
    </v-row>
    <v-dialog
      eager
      v-model="printDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="form-modal-header">
          <v-btn color="white" text>
            <v-icon @click="printDialog = false">mdi-close</v-icon>
          </v-btn>
          <span class="white--text">Imprimir mapa</span>
          <v-spacer></v-spacer>
          <v-btn color="white" class="font-17" @click="save()" text> Salvar </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="my-6 d-flex flex-column align-center">
            <p class="align-self-start">
              Clique e arraste para posicionar o mapa abaixo na região que deseja imprimir
            </p>
            <p class="align-self-start">
              Ctrl + Scroll do mouse para alterar o zoom
            </p>
            <div class="printable d-flex" ref="printable">
              <div class="printable-map" ref="mapPrint"></div>
              <div v-if="printDialog" class="d-flex flex-column flex-grow-1 printable-legend justify-space-between pt-6">
                <div class="d-flex justify-end pr-10">
                  <v-img src="@/assets/compass-rose.png" max-width="120"/>
                </div>
                <div class="pb-6 px-4 printable-bordered">
                  <div class="d-flex justify-center mb-6">
                    <v-img src="@/assets/iql_logo.png" max-width="120"/>
                  </div>
                  <h2 class="text-center mb-2" v-if="selectedFilter === 'Produtividade'">
                    {{ payload.farm.name }} - {{ payload.producer.user.name}}
                  </h2>
                  <h2 class="text-center" v-if="selectedFilter === 'Produtividade'">
                    Mapa de produtividade de {{ actualCulture }} por talhão
                  </h2>
                  <h2 class="text-center" v-else-if="selectedFilter === 'Distribuição'">
                    Mapa de distribuição de {{ selectedCulture === 'all' ? 'Culturas' : `Cultivares de ${actualCulture}` }}
                  </h2>
                  <h3 class="my-6">Legenda:</h3>
                  <div v-if="selectedFilter === 'Produtividade'" class="pl-4">
                    <div v-if="selectedCulture === 0">
                      <div v-for="interval,index of soyRuler" :key="interval">
                        <custom-legend
                          v-if="index<cultivars_colors.length"                
                          :label="`${interval} \u0020 \u0020 s/ha`"
                          :color="productivity_colors[index]"
                        ></custom-legend>
                      </div>
                    </div>
                    <div v-if="selectedCulture === 1">
                      <div v-for="interval,index of cornRuler" :key="interval">
                        <custom-legend
                          v-if="index<cultivars_colors.length"                
                          :label="`${interval} \u0020 \u0020 s/ha`"
                          :color="productivity_colors[index]"
                        ></custom-legend>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="selectedFilter === 'Distribuição'" class="pl-4">
                    <span>
                      {{ selectedCulture ==='all' ?'Culturas:':'Cultivares Utilizados:' }}
                    </span>
                    <div v-if="selectedCulture !=='all'">
                      <div v-for="(cultivar, index) in usedCultivars"
                          :key="index">
                        <custom-legend
                          v-if="index<cultivars_colors.length"                
                          :label="`${cultivar.name} - ${cultivar.area.toFixed(1)}ha (${cultivar.percentage.toFixed(1)}%)`"
                          :color="cultivars_colors[index]"
                        ></custom-legend>
                      </div>
                    </div>
                    <div v-else>
                      <custom-legend
                        v-for="(culture, index) in allCultures"
                        :key="index"
                        :label=culture.name
                        :color="cultivars_colors[index]"
                      ></custom-legend>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

//Components
import Toolbar from "@/components/crud/Toolbar.vue";
import SimpleSelect from "@/components/inputs/SimpleSelect.vue";
import CustomLabel from "@/components/texts/CustomLabel.vue";
import CustomLegend from "@/components/texts/Legend.vue";

import PlotRecords from "@/domain/records/plot-records/plot-records";
import PlotRecordFields from "@/domain/records/plot-records-fields/plot-records-fields";

import { kml } from "@tmcw/togeojson";
import center from "@turf/center";
import { randomColor } from "@/support/utils/randomColor"
import html2canvas from 'html2canvas'

const GOOGLE_API_KEY = "AIzaSyAMilsT6hekddIGM0xewSIVWwcLaDokwFw";

export default {
  components: { Toolbar, SimpleSelect, CustomLabel, CustomLegend },
  name: "PlotRecordMap",
  props: {
    payload: {
      required: true,
    },
  },
  data() {
    return {
      //Filters
      filter: [
        { text: "Produtividade", id: 1 },
        { text: "Distribuição", id: 2 },
      ],
      selectedFilter: "Produtividade",
      selectedCulture: '',

      //Instances
      PlotRecordsService: null,
      PlotRecordsFieldsService: null,
      mapsLoader: null,
      googleMap: null,

      //Dialog Controllers
      printDialog:false,

      //Data
      fieldsData: null,
      kml:null,
      filteredKml:null,
      filteredData:null,
      fieldsWithRecords: new Set(),
      productivityValues: [],
      usedCultivars: [],
      actualData: {
        feature: {},
        field_name: "--",
        cultivars: [],
        productivity: "N/a",
      },
      totalSowedArea: 0,
      predominantCultivar: {},
      allCultures:[],
      cultures:[],

      //Flags
      loaded: false,

      //Rulers
      soyRuler:['0-15','15-30','30-40','40-50','50-60','60+'],
      cornRuler:['0-70','70-100','100-130','130-160','160-190','190+'],      

      //Colors
      productivity_colors: [
        "#980000",
        "#ff0000",
        "#ff9900",
        "#ffff00",
        "#00ff00",
        "#38761d",
      ],
      cultivars_colors: [
        "#216c2e",
        "#fff333",
        "#c5a3ff",
        "#fe5900",
        "#0f3f62",
        "#ff3333",
        "#9C27B0",
        "#4a4a4a",
      ],
    };
  },
  watch: {
    payload: {
      handler() {
        if (this.payload) this.fetchData();
      },
    },
    loaded: {
      handler(value) {
        if (value) this.loadMap();
      },
      immediate: true,
    },
  },
  computed: {
    actualCulture() {
      let name = "--";
      name = this.cultures.find(culture=> culture.id === this.selectedCulture) 
      return name?.name ?? '--'
    }
  },
  mounted() {
    this.initialize();
    if (this.payload) this.fetchData();
  },
  methods: {
    async initialize() {
      this.PlotRecordsService = PlotRecords;
      this.PlotRecordsFieldsService = PlotRecordFields;

      this.mapsLoader = new Loader({
        apiKey: GOOGLE_API_KEY,
        libraries: ["drawing"],
      });
    },

    async fetchData() {
      this.loaded = false;
      [this.kml, this.fieldsData] = await Promise.all([
        this.fetchKml(),
        this.fetchFields(),
      ]);

      this.changeMap();

      this.loaded = true;
    },
    fetchFields() {
      return new Promise((resolve) => {
        this.PlotRecordsFieldsService.list(this.payload.id).then((res) => {
          resolve(res.data);
        });
      });
    },
    fetchKml() {
      return new Promise((resolve) => {
        const data = {id:this.payload.farm.id}

        this.PlotRecordsService.getKML(data).then((res) => {
          const kml = Buffer.from(res.data, "base64");
          const kmlJson = this.kmlToJson(kml);

          resolve(kmlJson);
        });
      });
    },
    changeMap(){
      const cultures = this.fieldsData.map(field => field.culture)
      this.allCultures=[];
      this.cultures=[];

      if(this.selectedFilter ==="Distribuição") this.cultures.push({
        id:"all",
        name:"Todas as Culturas"
      })

      if(cultures.length > 0){
        for( let culture of cultures){
          if(!this.allCultures.map(c => c.id).includes(culture.id)){
            this.allCultures.push(culture)          
            this.cultures.push(culture)
          }
        }
        this.selectedCulture= !this.selectedCulture ? this.cultures[0].id
                              : this.selectedFilter ==="Produtividade" && this.selectedCulture === "all" ? this.cultures[0].id
                              : this.selectedCulture
  
        if(this.selectedCulture === "all")
          this.filteredData = this.fieldsData
        else
          this.filteredData = this.fieldsData.filter(field => field.culture_id === this.selectedCulture)
      }
      else {
        this.filteredData = this.fieldsData;
      }
      
      this.filteredKml = JSON.parse(JSON.stringify(this.kml))


      if(this.loaded) {
        this.loadMap()
      }
    },

    async loadMap() {
      const map = this.$refs.map; //Get div to build map
      const mapPrint = this.$refs.mapPrint;

      this.fieldsWithRecords= new Set();
      this.productivityValues= [];
      this.usedCultivars= [];
      this.actualData= {
        feature: {},
        field_name: "--",
        cultivars: [],
        productivity: "N/a",
      };
      this.totalSowedArea= 0;
      this.predominantCultivar= {};

      if (this.filteredKml.features.length > 0) {
        //Calculates the center of KML
        const calcCenter = center(this.kml);
        const kmlCenter = {
          lng: calcCenter.geometry.coordinates[0],
          lat: calcCenter.geometry.coordinates[1],
        };
        this.buildDataToMap();

        this.getPredominantCultivar();        

        this.computeStyle();

        //Load map
        await this.mapsLoader.load().then((google) => {
          this.googleMap = new google.maps.Map(map, {
            center: kmlCenter,
            zoom: 13,
            mapTypeId: "satellite",
            disableDefaultUI: true,
          });

          this.googleMap.data.addGeoJson(this.filteredKml);
          let fillColor= this.selectedFilter === "Produtividade"? 'fillColor' 
                         : this.selectedCulture ==="all" ? 'fillColorCulture'
                         :'fillColorCultivar'


          this.googleMap.data.setStyle((feature) => {
            return {
              fillColor: feature.getProperty(fillColor),
              fillOpacity: feature.getProperty("fillOpacity"),
              strokeWeight: 1.5,
            };
          });

          this.googleMap.data.addListener("mouseover", this.handleMouseOver);
        });
        await this.mapsLoader.load().then((google) => {
          const printMap = new google.maps.Map(mapPrint, {
            center: kmlCenter,
            zoom: 13,
            mapTypeId: "roadmap",
            disableDefaultUI: true,            
            scaleControl: true,
          });

          printMap.data.addGeoJson(this.filteredKml);
          let fillColor= this.selectedFilter === "Produtividade"? 'fillColor' 
                         : this.selectedCulture ==="all" ? 'fillColorCulture'
                         :'fillColorCultivar';

          printMap.data.setStyle((feature) => {
            return {
              fillColor: feature.getProperty(fillColor),
              fillOpacity: feature.getProperty("fillOpacity"),
              strokeWeight: 1.5,
            };
          });
        });
      }
      else {
        map.innerHTML="<p class='font-weight-bold'> KML não cadastrado</p>"
      }
    },
    handleMouseOver(event){
      if (this.selectedFilter === "Distribuição") return
      if (this.fieldsWithRecords.has(event.feature.getProperty("name"))) {
        if (this.actualData.feature)
          this.googleMap.data.overrideStyle(this.actualData.feature, {
            fillOpacity: 0.6,
          });

        this.actualData = event.feature.getProperty("data");
        this.actualData.feature = event.feature;
        this.googleMap.data.overrideStyle(event.feature, {
          fillOpacity: 1,
        });
      }
    },
    buildDataToMap() {
      this.filteredKml.features.forEach((feature) => {
        const properties = feature.properties;
        let data = {
          field_name: null,
          cultivars: [],
          productivity: null,
          mainCultivar: null,
        };
        for (let i = 0; i < this.filteredData.length; i++) {
          let fieldName = this.filteredData[i].field.name || "";
          fieldName = fieldName.split(" - ")[0]
          const kmlFieldName = properties.name.split(" - ")[0]

          if (fieldName === kmlFieldName) {
            const cultivar = this.filteredData[i];

            data.field_name = properties.name;
            data.cultivars.push({
              name: cultivar.cultivar.name,
              culture: cultivar.culture_id,
              id:cultivar.cultivar_id,
              sowed_field_area: cultivar.sowing_area_field_percentage,
              area:cultivar.sowing_area,
              sowed_farm_area: cultivar.sowing_area_farm_percentage,
              productivity: cultivar.cultivar_productivity,
            });
          }
        }       
        if (data.field_name) {
          data.mainCultivar = this.getMainCultivar(data.cultivars);
          data.productivity = data.mainCultivar.productivity;

          this.fieldsWithRecords.add(data.field_name);
          this.productivityValues.push(Number(data.productivity));
          data.cultivars.forEach((cultivar) => {
            this.usedCultivars.push({
              name:cultivar.name,
              area:Number(cultivar.area),
              percentage:cultivar.sowed_farm_area,
              id:cultivar.id
              });
          });

          Object.assign(properties, { data: data });
        }
      });
    },

    kmlToJson(kmlb64) {
      //Convert b64 kml to string
      const kmlRaw = Buffer.from(kmlb64, "base64");
      let kmlString = kmlRaw.toString();

      //Remove problematic schemaLocation (generated by ArcGis)
      const xsiIndex = kmlString.indexOf('xsi:schemaLocation="');
      const xsiLastIndex = kmlString.indexOf(
        '"',
        kmlString.indexOf('"', xsiIndex) + 1
      );

      kmlString =
        kmlString.slice(0, xsiIndex) + kmlString.slice(xsiLastIndex + 1);

      //Convert the string to a XML Document
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(kmlString, "text/xml");

      //Convert the XML to GeoJson
      return kml(xmlDoc);
    },

    getPlotColor(productivity,culture){
      let index = 0;
      if(culture === 1){//Soja
        index    = productivity <= 15 ? 0 : 
                  productivity <= 30 ? 1 :
                  productivity <= 40 ? 2 :
                  productivity <= 50 ? 3 :
                  productivity <= 60 ? 4 :
                  5;
      }
      if(culture === 2){ //Milho
        index =   productivity <= 70 ? 0 : 
                  productivity <= 100 ? 1 :
                  productivity <= 130 ? 2 :
                  productivity <= 160 ? 3 :
                  productivity <= 190 ? 4 :
                  5;
      }
      return index
    },

    computeStyle() {
      this.filteredKml.features.forEach((feature) => {
         
        const data = feature.properties.data;
        const properties = feature.properties;

        let fillColor, fillOpacity, fillColorCultivar, fillColorCulture;
        if (data) {
          const prod= Number(data.productivity)

          let index = this.getPlotColor(prod,data.mainCultivar.culture)         

          fillColor = this.productivity_colors[index];
          fillOpacity = 0.8;

          let cultivarIndex = [...this.usedCultivars]
            .map(cultivar=>cultivar.name)
            .indexOf(data.mainCultivar.name)
          
          if(cultivarIndex >= this.cultivars_colors.length){
            this.cultivars_colors.push(randomColor())
          }

          const cultureIndex = this.allCultures
            .map(culture=>culture.id)
            .indexOf(data.mainCultivar.culture)

          fillColorCulture = this.cultivars_colors[cultureIndex];
        
          fillColorCultivar = this.cultivars_colors[cultivarIndex] ;

          Object.assign(properties, {
            fillColor,
            fillOpacity,
            fillColorCultivar,
            fillColorCulture,
          });
        }
      });
    },

    async save(){
      const newWindow = window.open()
      const img = await html2canvas(this.$refs.printable);

      const image = new Image()

      image.src = img.toDataURL()

      newWindow.document.body.appendChild(image)
      newWindow.document.body.style.backgroundColor='#222222'
      newWindow.document.body.style.display='flex'
      newWindow.document.body.style.justifyContent='center'
      newWindow.document.body.style.alignItems='center'

    },

    getMainCultivar(cultivars) {
      const cultivarsNames = new Set(
        cultivars.map((cultivar) => cultivar.name)
      );
      let mainCultivarSum = 0;
      let totalFarmSum = 0;
      let mainCultivar;

      cultivarsNames.forEach((cultivarName) => {
        let areaSum = 0;
        cultivars.forEach((cultivar) => {
          if (cultivar.name === cultivarName) {
            areaSum += cultivar.sowed_field_area;
            totalFarmSum += cultivar.sowed_farm_area;
          }

          if (areaSum > mainCultivarSum) {
            mainCultivarSum = areaSum;
            mainCultivar = Object.assign(cultivar, { totalArea: totalFarmSum });
          }
        });
      });
      this.totalSowedArea += totalFarmSum;
      return mainCultivar;
    },

    getPredominantCultivar() {      
      let usedCultivars=[];
      this.usedCultivars.forEach(
        (cultivar)=>{
          const savedCultivarIndex = usedCultivars.findIndex($cultivar=>cultivar.id === $cultivar.id)
          if(savedCultivarIndex > -1){
            usedCultivars[savedCultivarIndex].percentage+=cultivar.percentage
            usedCultivars[savedCultivarIndex].area+=cultivar.area          
          }
          else{
            usedCultivars.push(cultivar)
          }
        }
      )

      this.usedCultivars = usedCultivars
      this.usedCultivars = this.usedCultivars.sort((a,b)=>{
        return  b.area - a.area
      })

      this.predominantCultivar = this.usedCultivars[0];
    },
    getRandomColor(){
      return randomColor()
    }
  },

};
</script>

<style>
.map-container {
  background-color: #f3f3f3;
}

.printable {
  height: 800px;
  aspect-ratio: 1.41;
  overflow: hidden;
  background-color: red;
  position:relative
}

.printable-map {
  height: 825px;
  width: 100%;
}

.printable-legend {
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 25%;
  right: 0px;
  background-color: transparent;  
}

.printable-bordered {
  background-color: #e8e4dc;
}
</style>
