<template>
  <div>
    <toolbar
      :toolbarName="dynamicToolbarName"
      :openDetails="openDetails"
      :permissions="permissions.access_cultivate"
    />

    <form-dialog
      v-if="formDialog"
      :dialog="formDialog"
      :formName="dynamicFormName"
    >
      <v-form ref="formPlotRecordField">
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="ID do Lote"/>
            <simple-text-field v-model="lastLotId"
                              disabled
                              placeholder="ID do Lote"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" sm="4">
            <custom-label label="Cultura"/>
            <simple-select :items="cultures"
                          v-model="payload.culture_id"
                          itemText="name"
                          itemValue="id"
                          placeholder="Selecione a Cultura"
                          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true}"
                          required
                          :rules="requiredRules"
                          @custom-change="getCultivarBrands()"
            />
          </v-col>

          <v-col cols="12" md="4" sm="4">
            <custom-label label="Marca"/>
            <simple-select :items="cultivarBrands"
                          v-model="payload.cultivar_brand_id"
                          itemText="name"
                          itemValue="id"
                          placeholder="Selecione a Marca"
                          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true}"
                          required
                          :rules="requiredRules"
                          @custom-change="getCultivars()"                          
                          :disabled="!cultureSelected"
            />
          </v-col>

          <v-col cols="12" md="4" sm="4">
            <custom-label label="Cultivares"/>
            <simple-select :items="cultivars"
                          v-model="payload.cultivar_id"
                          itemText="name"
                          itemValue="id"
                          placeholder="Selecione o Cultivar"
                          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true}"
                          required
                          :rules="requiredRules"
                          @custom-change="getNonResistantDiseasesByCultivar()"
                          :disabled="!cultivarBrandSelected || !cultureSelected"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" sm="4">
            <custom-label label="Data do lote"/>
              <simple-text-field v-model="payload.lot_date"
                                type="date"
                                required
                                placeholder="Data do lote"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" sm="4">
            <custom-label label="Talhão"/>
            <simple-select :items="fields"
                          v-model="payload.field_id"
                          itemText="name"
                          itemValue="id"
                          placeholder="Selecione o Talhão"
                          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true}"
                          @custom-change="changeField()"
                          :disabled="!cultivarSelected"
            />
          </v-col>

          <v-col cols="12" md="4" sm="4">
            <custom-label label="Área de semeadura"/>
            <div style="display: inline; padding: 2px">
              <simple-text-field v-model="payload.sowing_area"
                                v-input-decimal-mask="decimalMask"
                                :key="componentKey"
                                ref="inputSowingArea"
                                class="input-sowing-area"
                                required
                                placeholder="Área de semeadura"
                                @custom-key-up="changeSowingArea()"
                                :disabled="!openDetails && remainingArea.remainingArea == 0"
              />
            </div>
          </v-col>

          <v-col cols="12" md="4" sm="4">
            <div style="padding-top: 2.4rem; padding-left: 1rem;">
              <custom-label
                v-if="payload.field_id"
                class="font-weight-bold"
                style="font-size: 18px;"
                :label="`/${remainingHectares} h.a (hectares restantes)`"
              />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Produtividade do Cultivares"/>
            <simple-text-field v-model="payload.cultivar_productivity"
                              v-input-decimal-mask="decimalMask"
                              :key="componentKey"
                              required
                              placeholder="Produtividade do Cultivares"
            />
          </v-col>
        </v-row>

        <v-row v-if="showTableDiseases" style="margin-top:2.5rem;">
          <v-col>
            <custom-label label="Match de Doenças" class="font-weight-bold"/>
            <data-table :headers="headersDiseases"
              :fetching="fetching"
              :data="diseases"
              :objectName="objectNameDiseases"
              :itemsPerPage="5"
              :showLoadAgain="false"
              class="data-table-diseases"
            >
            </data-table>
          </v-col>
        </v-row>
      </v-form>
    </form-dialog>

    <data-table
      :headers="headers"
      :fetching="fetching"
      :data="data"
      :objectName="objectName"
      @loadAgain="loadAgain"
      class="data-table-plot-record-fields"
    >
      <template v-slot:[`item.lot_date`]="{ item }">
        {{ item.lot_date | date }}
      </template>

      <template v-slot:[`item.cultivar.name`]="{ item }">
        {{ item.cultivar.name + ' - ' + item.cultivar_brand.name }}
      </template>

      <template v-slot:[`item.sowing_area`]="{ item }">
        {{ parseFloat(item.sowing_area) + 'ha (' + Number(item.sowing_area_field_percentage) + '%)' }}
      </template>

      <template v-slot:[`item.field.name`]="{ item }">
        {{ item.field
           ? item.field.name + ' (' + parseFloat(item.field.size) + 'ha)'
           : ""
        }}
      </template>

      <template v-slot:[`item.cultivar_productivity`]="{ item }">
        {{ parseFloat(item.cultivar_productivity) }}
      </template>

      <template v-slot:[`item.actions`]="{item}">
        <v-icon class="ml-4" small @click="view(item)">
          mdi-information-outline
        </v-icon>

        <v-icon v-if="authoritySlug === 'super-admin' || permissions.access_cultivate"
          style="color:#707070;" class="ml-4" small @click="edit(item)">
          mdi-pencil
        </v-icon>

        <v-icon v-if="authoritySlug === 'super-admin' || permissions.access_cultivate"
          class="ml-4" small @click="deleteItem(item)">
          mdi-trash-can-outline
        </v-icon>
      </template>
    </data-table>

    <confirm-destroy-dialog
      :title="'este lote'"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />
  </div>
</template>

<script>
import PlotRecordFields from "@/domain/records/plot-records-fields/plot-records-fields";
import Cultures from "@/domain/cultures/cultures";
import CultivarBrands from "@/domain/cultivar-brands/cultivar-brands";
import Cultivars from "@/domain/cultivars/cultivars.js";
import Fields from "@/domain/fields/fields";
import Diseases from "@/domain/diseases/diseases.js";

import DataTable from "@/components/data-table/DataTable";
import Toolbar from "@/components/crud/Toolbar";
import FormDialog from "@/components/crud/FormDialog";
import SimpleTextField from "@/components/inputs/SimpleTextField.vue";
import SimpleSelect from "@/components/inputs/SimpleSelect.vue";
import CustomLabel from "@/components/texts/CustomLabel";
import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";

import {getItem} from '@/services/local-storage.service';

import { VMoney } from "v-money-no-negative";

export default {
  directives: { inputDecimalMask: VMoney },
  name: "PlotRecordFields",
  components: {
    DataTable,
    Toolbar,
    FormDialog,
    SimpleTextField,
    SimpleSelect,
    CustomLabel,
    ConfirmDestroyDialog
  },

  props: {
    plotRecord: {
      required: true
    },
  },

  data() {
    return {
      objectName: "lotes registrados",
      objectNameDiseases: "doenças",
      toolbarName: "Lotes Registrados",
      formName: "Adicionar Lote",

      fetching: true,
      data: [],
      cultures: [],
      cultivarBrands: [],
      allCultivars:[],
      cultivars: [],
      fields: [],
      diseases: [],

      plotRecordFieldService: null,
      cultureService: null,
      cultivarBrandService: null,
      fieldService: null,
      diseaseService: null,

      payload: null,

      openDetails: false,
      formDialog: false,
      lastLotId: "",
      cultivarBrandSelected: false,
      cultureSelected: false,
      cultivarSelected: false,
      showConfirmDestroyDialog: false,
      showTableDiseases: false,

      authoritySlug: "",
      permissions: "",
      componentKey: 0,
      remainingArea: {},
      remainingHectares: 0,
      initialSowingArea: 0,
      initialField: null,

      decimalMask: {
        decimal: ',',
        thousands: '.',
      },

      requiredRules: [v => (!!v || v === false) || "O campo é obrigatório"]
    };
  },

  methods: {
    async getPlotRecordFields() {
      this.fetching = true;
      this.data = [];

      await this.plotRecordFieldService
      .list(this.plotRecord.id)
      .then(result => {
        this.data=result.data
      })
      .catch(({response}) => {
        this.fetching = false;
        const {error} = response.data;
        this.$toasted.show(error, {
          type: "error",
        });
      });

      this.fetching = false;
    },

    getLastLotId() {
      const loader = this.$loading.show();

      this.plotRecordFieldService.lastLotId().then(result => {
        if (result.lastLotId === '') result.lastLotId = 0;

        this.lastLotId = parseInt(result.lastLotId) + 1;
        this.lastLotId = '#' + this.lastLotId;
      });

      loader.hide();
    },

    getCultures() {
      let loader    = this.$loading.show();
      this.cultures = [];

      this.cultureService.all().then((result) => {
        result.data.map((itm) => {
          this.cultures.push(itm);
        });
      });

      loader.hide();
    },

    getCultivarBrands() {
      let loader = this.$loading.show();
      
      this.cultivarBrands  = [];
      this.cultivars = [];
      this.cultivarBrandSelected = false;
      this.cultivarSelected = false;
      this.cultureSelected = true;
      this.payload.cultivar_brand_id = null;
      this.payload.cultivar_id = null;

      this.cultivarBrandService.all(this.payload.culture_id).then(result => {
        result.data.map(item => {
          this.cultivarBrands.push(item);
        });
        loader.hide();
      })
      .catch(({response}) => {
        loader.hide();
        const {error} = response.data;
        this.$toasted.show(error, {
          type: 'error'
        });
      });

    },

    async getCultivars() {
      const cultivarBrandId = this.payload.cultivar_brand_id;
      let loader = this.$loading.show();
      this.allCultivars = [];
      this.payload.cultivar_id = null;
      this.cultivarSelected = false;

      await this.cultivarService.getByBrand(cultivarBrandId).then(result => {
        result.data.map(cultivar => this.allCultivars.push(cultivar));
      })
      .catch(({response}) => {
        loader.hide();
        const {error} = response.data;
        this.$toasted.show(error, {
          type: 'error'
        });
      });

      this.cultivars= this.allCultivars.filter(cultivar => cultivar.culture_id === this.payload.culture_id)

      if (this.openDetails) {
        this.cultivars.push({
          'id'   : this.payload.cultivar.id,
          'name' : this.payload.cultivar.name
        });
      }

      this.cultivarBrandSelected = true;
      loader.hide(); 
    },

    getFieldsByFarm() {
      if (this.authoritySlug === "producer") return;
      let loader  = this.$loading.show();
      this.fields = [];

      this.fields.push({'id' : '', 'name' : 'Sem Talhão'});

      this.fieldService.listByFarm(this.plotRecord.farm_id).then((result) => {
        result.data.map((itm) => {
          this.fields.push(itm);
        });
      });

      loader.hide();
    },

    getNonResistantDiseasesByCultivar() {
      const cultivarId = this.payload.cultivar_id ?? "";
      const fieldId    = this.payload.field_id ?? "";
      let loader = this.$loading.show();
      this.diseases = [];     

      this.cultivarSelected = !!cultivarId

      if (!fieldId || !cultivarId) {
        this.showTableDiseases = false;
        loader.hide();
        return;
      }

      this.diseaseService
      .listNonResistantByCultivar(cultivarId, fieldId)
      .then(result => {
        result.data.map(item => {
          this.diseases.push(item);
        });
      })
      .catch(({response}) => {
        const {error} = response.data;
        this.$toasted.show(error, {
          type: 'error'
        });
      });

      loader.hide();
      this.showTableDiseases = true;
    },

    getRemainingArea(fieldId, harvest) {
      const loader = this.$loading.show();

      this.plotRecordFieldService.getRemainingArea(fieldId, harvest)
      .then(result => {
        this.remainingArea = Object.assign({}, result);
        this.remainingHectares = this.remainingArea.remainingArea;
      })
      .catch(({response}) => {
        const {error} = response.data;
        this.$toasted.show(error, {
          type: 'error'
        });
      });

      loader.hide();
    },

    changeSowingArea() {

      if (this.payload.field_id == "") return;

      let initialSowingArea = 0;

      if (this.initialField == this.payload.field_id) {
        initialSowingArea = this.initialSowingArea;
      }

      let inputSowingArea = this.$refs.inputSowingArea.value.replace('.', '').replace(',','.');
      inputSowingArea     = parseFloat(inputSowingArea);

      this.remainingHectares = parseFloat(this.remainingArea.remainingArea);

      if (inputSowingArea <= initialSowingArea) {
        const difference       = parseFloat(initialSowingArea) - inputSowingArea;
        this.remainingHectares = this.remainingHectares + difference;
        this.remainingHectares = this.remainingHectares.toFixed(2);
        return;
      }

      const difference       = Math.abs(initialSowingArea - inputSowingArea);
      this.remainingHectares = parseFloat(this.remainingHectares) - difference;

      if (this.remainingHectares < 0) {
        this.remainingHectares   = parseFloat(this.remainingArea.remainingArea);
        this.payload.sowing_area = initialSowingArea;
        this.componentKey++;
      }

      this.remainingHectares = this.remainingHectares.toFixed(2);
    },

    changeField() {
      if (!this.initialField && this.payload.field_id == "") {
        this.payload.sowing_area = this.initialSowingArea;
        this.componentKey++;
        this.showTableDiseases = false;
        return;
      }
      
      this.payload.sowing_area = this.initialField != this.payload.field_id
        ? 0 : this.initialSowingArea;

      if (this.payload.field_id) {
        this.getRemainingArea(this.payload.field_id, this.plotRecord.harvest);
      }

      this.getNonResistantDiseasesByCultivar();
      this.componentKey++;
    },

    initialize() {
      this.plotRecordFieldService = PlotRecordFields;
      this.cultureService         = Cultures;
      this.cultivarBrandService   = CultivarBrands;
      this.cultivarService        = Cultivars;
      this.fieldService           = Fields;
      this.diseaseService         = Diseases;

      this.payload = this.plotRecordFieldService.newData();

      this.authoritySlug = getItem('authoritySlug');
      this.permissions   = JSON.parse(getItem('permissions'));

      if (this.authoritySlug !== 'super-admin' && !this.permissions.access_cultivate) {
        this.openDetails = true;
      }

      this.getPlotRecordFields();
      this.getLastLotId();
      this.getCultures();
      this.getFieldsByFarm();
    },
    view(item) {
      this.payload = Object.assign({}, item);
      this.$emit('plot-record-field-details', this.payload);
    },

    create() {
      this.componentKey += 1;
      this.getLastLotId();
      this.payload = {};
      this.remainingArea = {};
      this.diseases = [];
      this.cultivarBrands  = [];
      this.cultivars = [];
      this.cultivarBrandSelected = false;
      this.cultivarSelected = false;
      this.cultivarSelected = false;
      this.cultureSelected = false;
      this.showTableDiseases = false;
      this.remainingHectares = 0;
      this.initialSowingArea = 0;
      this.initialField = null;
      this.formDialog = true;
    },

    async edit(item) {
      this.componentKey += 1;

      this.payload   = Object.assign({}, item);
      this.lastLotId = '#' + this.payload.id;
      this.initialSowingArea = this.payload.sowing_area;
      this.initialField      = this.payload.field_id;

      this.getRemainingArea(item.field_id, this.plotRecord.harvest);

      await this.getCultivarBrands();
      this.payload.cultivar_brand_id = item.cultivar_brand_id;
      await this.getCultivars();
      this.payload.cultivar_id = item.cultivar_id

      this.getNonResistantDiseasesByCultivar();

      this.openDetails = true;
      this.formDialog = true;
    },

    deleteItem(item) {
      this.payload = Object.assign({}, item);
      this.showConfirmDestroyDialog = true;
    },

    closeConfirmDestroyDialog() {
      this.showConfirmDestroyDialog = false;
    },

    closeFormDialog() {
      this.formDialog = false;
      this.openDetails = false;
    },

    save() {
      if (!this.$refs.formPlotRecordField.validate()) {
        return;
      }

      if (!this.openDetails && this.remainingArea.remainingArea == 0) {
          this.$toasted.show(
            "Não há hectares restantes.",
            {
              type: "error",
              position: "top-right",
            }
          );

          return false;
      }

      let loader = this.$loading.show();

      this.payload.lot_record_id = this.plotRecord.id;

      this.payload.sowing_area = this.payload.sowing_area.replace('.', '').replace(',','.');
      this.payload.cultivar_productivity = this.payload.cultivar_productivity.replace('.', '').replace(',','.');

      this.payload.disease_id = this.payload.disease_id !== undefined
        ? this.payload.disease_id : null;

      this.payload.field_id = this.payload.field_id
        ? this.payload.field_id : "";

      if (!this.openDetails) {
        this.plotRecordFieldService
          .create(this.payload)
          .then(() => {
            loader.hide();
            this.closeFormDialog();
            this.initialize();
            this.$emit('close-details');
            this.$toasted.show("Lote cadastrado com sucesso.", {
              type: "success",
            });
          })
          .catch(({ response }) => {
            loader.hide();
            const { error } = response.data;
            this.$toasted.show(error, {
              type: "error",
            });
          });
      } else {
        this.plotRecordFieldService
          .edit(this.payload)
          .then(() => {
            loader.hide();
            this.getPlotRecordFields();
            this.closeFormDialog();
            this.$emit('close-details');
            this.$toasted.show("Lote atualizado com sucesso.", {
              type: "success",
            });
          })
          .catch(({ response }) => {
            loader.hide();
            const { error } = response.data;
            this.$toasted.show(error, {
              type: "error",
            });
          });
      }
    },

    async destroy() {
      const loader = this.$loading.show();

      await this.plotRecordFieldService.delete(this.payload).then(() => {
        loader.hide();
        this.getPlotRecordFields();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Lote removido com sucesso.", {
          type: "success",
        });
      })
      .catch(() => {
        loader.hide();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Lote não foi removido.", {
          type: 'error'
        });
      });
    },

    loadAgain() {
      this.initialize();
    }
  },

  computed: {
    dynamicToolbarName() {
      return this.toolbarName;
    },

    dynamicFormName() {
      if (this.openDetails) {
        return "Editar Lote";
      }

      return this.formName;
    },

    headers() {
      return [
        {
          text: "Data",
          align: "start",
          value: "lot_date",
        },
        {
          text: "Cultivares",
          value: "cultivar.name",
          sortable: this.canBeSortable,
        },
        {
          text: "Área Plantada",
          value: "sowing_area",
          sortable: this.canBeSortable,
        },
        {
          text: "Talhão",
          value: "field.name",
          sortable: this.canBeSortable,
        },
        {
          text: "Produtividade",
          value: "cultivar_productivity",
          sortable: this.canBeSortable,
        },
        {text: 'Detalhes', value: 'actions', sortable: false}
      ]
    },

    headersDiseases() {
      return [
        {
          text: "Nome da Doença",
          value: "name",
          sortable: this.canBeSortable,
          class:"pl-0"
        }
      ]
    }
  },
  beforeMount() {
    this.initialize();
  },
};
</script>

<style scoped>
  .data-table-plot-record-fields {
    border: none !important;
    padding: 0 !important;
  }

  .data-table-diseases {
    border: none !important;
    padding: 0 !important;
    margin-top: 1rem !important;
  }

  ::v-deep .data-table-plot-record-fields th {
    text-align: center !important;
  }

  .input-sowing-area:after {
    content:'HA';
    position: absolute;
    top: 0.9rem;
    left: calc(90% - 5px);
    display: block;
    font-size: 15px;
    color: #212020;
  }
</style>
