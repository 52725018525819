import PlotRecordFieldsService from "./plot-records-fields.service";

class PlotRecordField {
  constructor(payload = {}) {
    Object.assign(this, {
      ...PlotRecordField.build(payload)
    });
  }

  static build(
      {
        id                           = '',
        culture_id                   = '',
        cultivar_brand_id            = '',
        cultivar_id                  = '',
        lot_date                     = '',
        sowing_area                  = '',
        field_id                     = '',
        cultivar_productivity        = '',
        lot_record_id                = '',
        disease_id                   = '',
        sowing_area_field_percentage = '',
        sowing_area_farm_percentage  = '',
        lastLotId                    = '',
        culture                      = {},
        cultivar                     = {},
        cultivar_brand               = {},
        field                        = {},
        disease                      = {},
        productivity                 = {},
        distribution                 = {},
        created_at                   = '',
        updated_at                   = '',
      }
  ) {
    return {
      id,
      culture_id,
      cultivar_brand_id,
      cultivar_id,
      lot_date,
      sowing_area,
      field_id,
      cultivar_productivity,
      lot_record_id,
      disease_id,
      sowing_area_field_percentage,
      sowing_area_farm_percentage,
      lastLotId,
      culture,
      cultivar,
      cultivar_brand,
      field,
      disease,
      productivity,
      distribution,
      created_at,
      updated_at,
    }
  }

  static newData(payload) {
    return new PlotRecordField(payload);
  }
  
  static list(plotRecordId) {
    return new Promise((resolve, reject) => {
      PlotRecordFieldsService
        .list(plotRecordId)
        .then(
          success => {
            const data = {...success};
            const list = Object.assign({}, {data});
            list.data  = data.data.data.map(item => this.newData(item));
            resolve(list);
          },

          error => {
            reject(error);
          }
        );
    });
  }

  static create(payload) {
    return new Promise((resolve, reject) => {
      PlotRecordFieldsService
        .create(payload)
        .then(
          success => {
            resolve(success);
          },
          
          error => {
            reject(error);
          }
        );
    });
  }

  static edit(payload) {
    return new Promise((resolve, reject) => {
      PlotRecordFieldsService
      .edit(payload)
      .then(
        success => {
          resolve(success);
        },
        
        error => {
          reject(error);
        }
      );
    })
  }

  static lastLotId() {
    return new Promise((resolve, reject) => {
      PlotRecordFieldsService
      .lastLotId()
      .then(
        success => {
          let lot = this.newData(success.data.data);
          resolve(lot);
        },
        
        error => {
          reject(error);
        }
      );
    });
  }

  static delete(payload) {
    return new Promise((resolve, reject) => {
      PlotRecordFieldsService
      .delete(payload)
      .then(success => {
        resolve(success);
      }, error => {
        reject(error);
      });
    });
  }

  static getRemainingArea(fieldId, harvest) {
    return new Promise((resolve, reject) => {
      PlotRecordFieldsService
      .getRemainingArea(fieldId, harvest)
      .then(
        success => {
          let response = success.data;
          resolve(response);
        },
        
        error => {
          reject(error);
        }
      );
    });
  }
}

export default PlotRecordField;
